<template>
  <div class="outer">
    <div class="sub-section">
      <div class="warpper">
        <ul>
          <li v-for="(item, index) in scienceList" :key="index">
            <a @click="goThere(item)">
              <div class="m-img">
                <img :src="item.servicePath + item.cover" alt="" />
              </div>
              <div class="m-info">
                <h6>{{ item.brief }}</h6>
                <p></p>
              </div>
            </a>
          </li>
        </ul>
        <div class="news-page" v-if="scienceList && scienceList.length > 0">
          <page-view :page="page" @pageChange="pageChange"></page-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageView from "@components/pageView";
export default {
  name: "",
  components: {
    pageView,
  },
  data() {
    return {
      scienceList: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      }
    };
  },

  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  watch: {
    getSubMenu: {
      deep: true,
      handler() {
        this.getList();
      },
    },
  },
  methods: {
    goThere(item) {
      this.$router.push({
        name: "newsInfo",
        query: {
          id: item.id,
          key: this.$route.name,
        },
      });
    },
    getList() {
      if (Object.keys(this.getSubMenu).length == 0) return;
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface, {
          pageSize: this.page.pageSize,
          pageNo: this.page.pageNo,
        }).then((res) => {
          this.scienceList = res.list;
          this.page.total = +res.total
        });
      }
    },
    //分页
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>