<template>
  <div class="outer">
    <science-banner></science-banner>
  </div>
</template>

<script>
import scienceBanner from "@components/scienceBanner";
export default {
  name: "",
  components: {
    scienceBanner,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>

</style>